<template>
    <div id="appPC">
        <div class="title">
            <img src="../../../assets/img/index/login-title.png" alt="" />
        </div>
        <div class="center">
            <div class="tit">用户登录</div>
            <a-form class="from" :model="formData" ref="formRef">
                <div class="from-div">
                    <a-form-item name="account" ref="account">
                        <a-input
                            class="account"
                            size="large"
                            v-model="formData.account"
                            placeholder="请输入账号"
                            @keyup.enter="onSubmit"
                        />
                    </a-form-item>
                    <a-form-item name="password" ref="password">
                        <a-input-password
                            v-model="formData.password"
                            class="password"
                            size="large"
                            visibilityToggle
                            placeholder="请输入密码"
                            @keyup.enter="onSubmit"
                        />
                    </a-form-item>
                </div>
                <div class="zidong-div">
                    <a-form-item>
                        <a-checkbox size="small" :checked="formData.autoLogin" @change="onChange"
                            >30天自动登录</a-checkbox
                        >
                    </a-form-item>
                </div>
                <div class="button-div">
                    <a-form-item>
                        <a-button
                            type="primary"
                            size="large"
                            class="login-btn"
                            :block="true"
                            :loading="formStateLoading"
                            @click="onSubmit"
                            >登录</a-button
                        >
                    </a-form-item>
                </div>
            </a-form>
        </div>

        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
    import md5 from 'js-md5'
    import * as Server from '@/api/server.js'

    export default {
        name: 'Login',
        computed: {
            percent() {
                return this.$store.state.percent
            },
            spin() {
                return this.$store.state.spin
            },
        },

        data() {
            return {
                formData: {
                    account: '',
                    password: '',
                    autoLogin: false,
                },
                formStateLoading: false,
            }
        },
        methods: {
            onChange(e) {
                this.formData.autoLogin = e.target.checked
            },
            async onSubmit() {
                if (!this.formData.account.trim()) {
                    this.$message.warning('请输入账号')
                    return
                }
                if (!this.formData.password.trim()) {
                    this.$message.warning('请输入密码')
                    return
                }
                let sendData = {
                    account: this.formData.account,
                    password: md5(this.formData.password),
                    noLogin30: this.formData.autoLogin,
                }

                let data = {
                    method: 'post',
                    url: 'f/user/login',
                    config: {
                        data: sendData,
                    },
                    loading: false,
                }
                let dataSource = await Server.Axios(data)

                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                } else if (dataSource.data.returnCode == 400) {
                    this.$message.error(dataSource.data.returnInfo)
                    return
                } else {
                    localStorage.setItem('token', dataSource.data.returnData.token)
                    this.$router.push({
                        name: 'Index',
                    })
                }
            },
        },
    }
</script>

<style lang="less" type="text/less" scoped>
    #appPC {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background: url('../../../assets/img/index/login-bg.png') no-repeat 0 center;
        background-size: 100%;

        .title {
            margin: -10vh auto -21vh;
            text-align: center;
            img {
                width: 90%;
            }
        }
        .center {
            width: 380px;
            height: 410px;
            background-size: 100%;
            // background-size: contain;
            background: url('../../../assets/img/index/login-center-bg.png') no-repeat;
            background-size: 100% 100%;
            margin: 0 auto;

            .tit {
                color: #fff;
                font-size: 0.18rem;
                margin: 40px auto;
                text-align: center;
            }

            /deep/.ant-form-item-control {
                display: flex;
                justify-content: center;
                margin-bottom: 5px;
            }
            .account {
                width: 300px;
                margin: 0 auto;
                border-radius: 6px;
                background: #1b53a3;
                border: 1px solid #2983c7 !important;
                font-size: 0.14rem !important;
                line-height: 42px;
                height: 42px;
            }
            .password {
                width: 300px;
                margin: 0 auto;
                /deep/.ant-input {
                    width: 300px;
                    margin: 0 auto;
                    border-radius: 6px;
                    background: #1b53a3;
                    border: 1px solid #2983c7 !important;
                    font-size: 0.14rem !important;
                    line-height: 42px;
                    height: 42px;
                }
                /deep/.anticon {
                    cursor: pointer;
                    color: #d4deed;
                }
            }
            .zidong-div {
                width: 300px;
                margin: 0 auto;
                /deep/.ant-form-item {
                    label {
                        color: #fff;
                    }
                }
                /deep/.ant-form-item-control {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                    line-height: 1px;
                }
            }
            .login-btn {
                width: 300px;
                margin: 0 auto;
                border-radius: 6px;
                background-image: linear-gradient(#0096ff, #0065ff);
                line-height: 42px;
                height: 42px;
            }
        }

        .main {
            flex: 1;
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }

    .main::-webkit-scrollbar {
        display: none;
    }

    .loading {
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
